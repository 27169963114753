import { render, staticRenderFns } from "./otherDocument.vue?vue&type=template&id=63a99371"
import script from "./otherDocument.vue?vue&type=script&lang=js"
export * from "./otherDocument.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports